import axios from "axios";
import Joi, { func } from "joi";
import $ from "jquery";
import {
	getData
} from "./burying-point";
// let btn = document.getElementById("submit");
// console.log('btn',btn);
// btn.onclick = function(){
//     console.log('这是第一种点击方式');
//     const data = {
//         agent: "Chrome",
//         agentVersion: "100.0.4896.60",
//         device: "pc",
//         os: "Windows10",
//         pass: "yyh123456",
//         platform: "pc",
//         refer: "logon",
//         user: "17607091584"
//     }
//     axios({
//         method: "post",
//         crossDomain: true,
//         url: `https://586.laifuyun.com/pcapi/v2/auth`,
//         data,
//     }).then((res) => {
//         console.log(res);
//         if(res.status === 200) {
//             // alert(res.data.msg);
//         } else {
//             alert('server not responding')
//         }
//         this.reset();
//     });
// }

window.inquiryForm = function () {
	return {
		// flag: sessionStorage.getItem('loginFlag') == '1'? true: false || sessionStorage.getItem('access_token')? true: false ,
		contNamet: localStorage.getItem("contNamet") || "",
		mailAddresst: localStorage.getItem("mailAddresst") || "",
		flag: localStorage.getItem("cs_access_token") ? true : false,
		name: localStorage.getItem("cs_contName")
			? localStorage.getItem("cs_contName")
			: "li",
		company: localStorage.getItem("cs_company")
			? localStorage.getItem("cs_company")
			: "LED flex",
		contName: "",
		mobile: "",
		mailAddress: "",
		custName: "",
		remarks: "",
		errMsg: {
			contName: "",
			mobile: "",
			mailAddress: "",
			custName: "",
			remarks: "",
		},
		// flag 用于控制买家中心跳转登录页还是详情页
		SingIn: function () {
			localStorage.setItem("cs_flag", "false");
		},
		CustomerCenter: function () {
			localStorage.setItem("cs_flag", "true");
		},
		SignOut: function () {
			this.flag = false;
			let data = {
				access_token: localStorage.getItem("cs_access_token"),
			};
			axios({
				method: "post",
				crossDomain: true,
				url: `https://thirddevexternal.laifuyun.com/buyerCenter/auth/loginOut`,
				data,
			}).then((res) => {
				if (res.status == 200) {
					localStorage.removeItem("cs_access_token");
					localStorage.removeItem("cs_contName");
					localStorage.removeItem("cs_company");
					window.location.href = window.location.origin;
				} else {
					alert("server not responding");
				}
			});
		},
		reset: function () {
			this.contName = "";
			this.mobile = "";
			this.mailAddress = "";
			this.custName = "";
			this.remarks = "";
		},
		resetMsg: function () {
			this.errMsg = {
				contName: "",
				mobile: "",
				mailAddress: "",
				custName: "",
				remarks: "",
			};
		},
		submitForm: function (e) {
			e.preventDefault();
			console.log("111111", e);
			const dom = document.getElementById("inquiry submit");
			const {
				domain,
				contName,
				mobile,
				mailAddress,
				custName,
				remarks,
				siteId,
				siteName,
				cId,
				spuId,
				inquirySuccessUrl,
				versionIteration,
				opctId
			} = this;
      const info = JSON.parse(localStorage.getItem('pageviewData'))
      console.log('点击唤起', info)
			const { error, value } = schema.validate({
				contName,
				mobile,
				mailAddress,
				custName,
				remarks,
			});
			this.resetMsg();
			if (error) {
				const { path: errKey, message } = error.details[0];
				this.errMsg[errKey] = message;
				return;
			}
			// dom.click();

			if (versionIteration == 1) {
				const data = {
					...value,
					enquUri: window.location.href,
					locallanguage: "cn",
					cid: cId || "",
					spuId: spuId || "",
					siteId: siteId || 0,
					siteName: siteName || "",
					spec1: '',
					spec2: '',
					spec3:  '',
					spec4: '',
					enquSource: 4,
					opctId: opctId,
					type: 1
				};
				var gclid = localStorage.getItem("gclid") || "";
				if (gclid != "") {
					data.gclid = gclid;
				}
	
				axios({
					method: "post",
					crossDomain: true,
					url: `${domain}/pcapi/bill/product/inquiry`,
					data,
				}).then((res) => {
					if (res.data.code === '0') {
						localStorage.setItem("contNamet", contName);
						localStorage.setItem(
							"mailAddresst",
							"@" + mailAddress.split("@")[1]
						);
						window.location.assign(`${inquirySuccessUrl}`);
            getData({
              ...info,
              elementId: 'inquiry submit',
              event: '$WebClick'
            })
					} else {
						alert("server not responding");
					}
					this.reset();
				});
			} else {
				const data = {
					...value,
					enquUri: window.location.href,
					locallanguage: "cn",
					cId: cId || "",
					spuId: spuId || "",
					siteId: siteId || 0,
					siteName: siteName || "",
					enquSource: 4,
				};
				var gclid = localStorage.getItem("gclid") || "";
				if (gclid != "") {
					data.gclid = gclid;
				}
	
				axios({
					method: "post",
					crossDomain: true,
					url: `${domain}/pcapi/v2/product/enquiry`,
					data,
				}).then((res) => {
					console.log(res);
					if (res.status === 200) {
						alert(res.data.msg);
						localStorage.setItem("contNamet", contName);
						localStorage.setItem(
							"mailAddresst",
							"@" + mailAddress.split("@")[1]
						);
						window.location.assign(`${inquirySuccessUrl}`);
            getData({
              ...info,
              elementId: 'inquiry submit',
              event: '$WebClick'
            })
					} else {
						alert("server not responding");
					}
					this.reset();
				});
			}
		},
	};
};

window.onload = f;
function f() {
	let appVersion = window.navigator.appVersion;
	let SingIn = document.getElementById("sing-in");
	let CustomerCenter = document.getElementById("customer-center");
	let BuyerDetails = document.getElementById("buyer-details");

	if (
		appVersion.indexOf("iPhone") != -1 ||
		appVersion.indexOf("Android") != -1
	) {
		console.log("shouji1", SingIn, CustomerCenter, BuyerDetails);
		SingIn.style.display = "none";
		CustomerCenter.style.display = "none";
		BuyerDetails.style.display = "none";
	}
	console.log("页面加载完毕", window.navigator.appVersion);

	// alert("页面加载完毕");
}

const schema = Joi.object({
	contName: Joi.string().max(30).required().label("First Name"),
	mobile: Joi.number().optional().allow("").label("Phone"),
	mailAddress: Joi.string()
		.email({
			minDomainSegments: 2,
			tlds: false,
		})
		.label("E-Mail"),
	custName: Joi.string()
		.optional()
		.allow("")
		.max(100)
		.label("Corporate Name"),
	remarks: Joi.optional().label("Purchasing Demand"),
});

//合成事件 hover()
// $(".header").hover(function (){
//     console.log('you触发吗');
//     $(".content").show();
// },function (){
//     console.log('没you触发吗')
//     $(".content").hide();
// });
//鼠标的移入移出
// $(".header").mouseover(function (){
// 	$(".content").show();
// })
// $(".content").mouseout(function (){
// 	$(".content").hide();
// });
