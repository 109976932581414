import "./modules/lazyload";
import $ from 'jquery'
import axios from 'axios';

const userAgent = navigator.userAgent;
const referrer = document.referrer || '';
const currentURL = window.location.href;

// 最近一次的加载
const latestReferrer = localStorage.getItem('latest_landing_page');

const recentSourcesUrl = localStorage.getItem('recentSources') || ''

// 获取浏览器信息
const browserObj = getExploreName(userAgent)

// 机型（手机）js获取不了，只能通过操作系统进行判断
function getUserAgent(userAgent) {
	let Version = ''

	// 判断是否为iPhone
	if (/(iPhone|iPad|iPod)/.test(userAgent)) {
		let isiPad = userAgent.match(/iPad/i) !== null;
		let isiPod = userAgent.match(/iPod/i) !== null;
		let iPhoneVersion = '';

		if (isiPad) {
			iPhoneVersion = 'iPad';
		} else if (isiPod) {
			iPhoneVersion = 'iPod';
		} else {
			let match = /(iPhone\sOS)\s([\d_]+)/.exec(userAgent);
			if (match !== null && match.length >= 3) {
				iPhoneVersion = 'iPhone ' + match[2].replace(/_/g, '.');
			}
		}
		Version = iPhoneVersion
	}

	// 判断是否为Android手机
	if (/Android/.test(userAgent)) {
		var match = userAgent.match(/Android\s([\d.]+)/);
		if (match !== null && match.length >= 2) {
			var androidVersion = 'Android ' + match[1];
			Version = androidVersion
		}
	}

	return Version
}

// 操作系统版本
function getOperatingSystem(userAgent) {
	let operatingSystem = "Unknown";

	if (userAgent.indexOf("Win") != -1) {
		// Windows
		if (userAgent.indexOf("Windows NT 10.0") != -1) {
			operatingSystem = "Windows 10";
		} else if (userAgent.indexOf("Windows NT 6.3") != -1) {
			operatingSystem = "Windows 8.1";
		} else if (userAgent.indexOf("Windows NT 6.2") != -1) {
			operatingSystem = "Windows 8";
		} else if (userAgent.indexOf("Windows NT 6.1") != -1) {
			operatingSystem = "Windows 7";
		} else if (userAgent.indexOf("Windows NT 6.0") != -1) {
			operatingSystem = "Windows Vista";
		} else if (userAgent.indexOf("Windows NT 5.1") != -1) {
			operatingSystem = "Windows XP";
		} else if (userAgent.indexOf("Windows NT 5.0") != -1) {
			operatingSystem = "Windows 2000";
		}
	} else if (userAgent.indexOf("Mac") != -1) {
		// Mac
		operatingSystem = "Mac OS";
	} else if (userAgent.indexOf("Linux") != -1) {
		// Linux
		operatingSystem = "Linux";
	} else if (userAgent.indexOf("Android") != -1) {
		// Android
		operatingSystem = "Android";
	} else if (userAgent.indexOf("iOS") != -1) {
		// iOS
		operatingSystem = "iOS";
	}

	return operatingSystem;
}

// 获取浏览器信息方法
function getExploreName(userAgent) {
	let browser = {
		browserName: '',
		browserVersion: ''
	}

	// 匹配浏览器名称和版本
	var regex = /(Firefox|Chrome|Safari|Opera|Edge)\/([\d.]+)/;
	var match = userAgent.match(regex);

	if (match) {
		browser.browserName = match[1];
		browser.browserVersion = match[2];
	}

	return browser
}

function getKeywordFromReferrer() {
	let referrer = document.referrer;
	let urlParams = new URLSearchParams(referrer);

	// Google
	if (referrer.includes("google.com")) {
		return decodeURIComponent(urlParams.get("q"));
	}

	// 百度
	if (referrer.includes("baidu.com")) {
		return decodeURIComponent(urlParams.get("wd"));
	}

	// 必应
	if (referrer.includes("bing.com")) {
		return decodeURIComponent(urlParams.get("q"));
	}

	// 搜狗
	if (referrer.includes("sogou.com")) {
		return decodeURIComponent(urlParams.get("query"));
	}

	// 360
	if (referrer.includes("so.com")) {
		return decodeURIComponent(urlParams.get("q"));
	}

	// 其他搜索引擎 todo
	// 如果有其他搜索引擎需要支持，可以继续添加类似的判断条件

	// 默认情况下返回空字符串
	return "前一个页面没有查询关键词";
}

if (referrer) {
	// 存储这一次的页面，用于下一次的最近加载页面
	localStorage.setItem('latest_landing_page', referrer)
}

// 获取流量来源
function analyzeTraffic(referrer, currentURL) {
	const config = {
		searchEngines: ["google", "bing", "yahoo", "baidu"],
		socialMediaDomains: ["facebook.com", "twitter.com", "linkedin.com", "instagram.com"],
		adKeywords: ["utm_source=google", "utm_source=facebook", "utm_medium=cpc"],
	};

	function containsAny(target, keywords) {
		return keywords.some(keyword => new RegExp(keyword, 'i').test(target));
	}

	function isSearchEngineTraffic(referrer, currentURL) {
		return containsAny(referrer + currentURL, config.searchEngines);
	}

	function isSocialMediaTraffic(referrer) {
		return containsAny(referrer, config.socialMediaDomains);
	}

	function isDirectTraffic(referrer) {
		return !referrer || referrer === "";
	}

	function isAdTraffic(currentURL) {
		return containsAny(currentURL, config.adKeywords);
	}

	function isReferralTraffic(referrer) {
		return !isSearchEngineTraffic(referrer, currentURL) && !isSocialMediaTraffic(referrer) && !isDirectTraffic(referrer) && !isAdTraffic(currentURL);
	}

	// 判断流量类型
	if (isSearchEngineTraffic(referrer, currentURL)) {
		return "搜索引擎流量";
	} else if (isSocialMediaTraffic(referrer)) {
		return "社交媒体流量";
	} else if (isAdTraffic(currentURL)) {
		return "广告流量";
	} else if (isReferralTraffic(referrer)) {
		return "引荐流量";
	} else if (isDirectTraffic(referrer)) {
		return "直接流量";
	} else {
		return "未知流量类型";
	}
}

let buryingPointData = {
	model: getUserAgent(userAgent), // 机型（手机） √
	referrer: '', // 来源页面 √
	referrer_host: '', // 来源页面host √
	referrer_host: '', // 来源页面host √
	os_version: getOperatingSystem(userAgent), // 操作系统版本(pc) √

	latestReferrerHost: '', // 最近来源host √
}

// 存储最近来源页面
if (!recentSourcesUrl) {
	localStorage.setItem('recentSources', referrer)
} else {
	const referrer_url = new URL(recentSourcesUrl);
	buryingPointData.latestReferrerHost = referrer_url.host
}

// -----------------来源--------------------
if (referrer) {
	const referrerUrl = new URL(referrer);
	const referrerHost = referrerUrl.host;
	buryingPointData.referrer = referrer // 来源
	buryingPointData.referrer_host = referrerHost
}

// 获取最近的浏览地址消息
if (latestReferrer) {
	const latestReferrerUrl = new URL(latestReferrer);
	const referrerHost = latestReferrerUrl.host;
	buryingPointData.latestReferrer = referrer // 最近来源
	buryingPointData.latestReferrerHost = referrerHost // 最近来源host
}
// -----------------来源 end--------------


// 接口传参的数据在此做一些处理
function getParams(params, sessionId) {
	/**
	 * 1. 持续时长：可以通过在页面加载完成和卸载前记录时间戳，并计算差值来获取页面停留时长。
	 * 2. 网页页面标题、地址和路径、host：这些信息可以通过浏览器提供的JavaScript API来获取，例如 document.title 可以获取页面标题，window.location.href 可以获取完整的页面地址，window.location.pathname 可以获取页面路径，window.location.host 可以获取页面的host。
	 * 3. 来源页面和来源页面host：可以通过 document.referrer 获取来源页面的URL，然后解析该URL获取来源页面host。
	 * 4. 操作系统版本、屏幕高度和宽度、机型（手机）、浏览器和浏览器版本、时区偏移量、制造商（手机）：这些信息可以通过浏览器的User-Agent字符串解析得到。可以使用第三方库或自定义解析函数来提取所需的信息。
	 * 5. 最近加载页面、最近来源host、最近来源网站、最近查询关键字：这些信息可能需要根据具体业务场景进行处理。可以通过在服务器端记录用户的访问日志并进行分析，或者使用第三方统计工具来获取这些信息。
	 **/
	const data = {
		"sessionId": sessionId,

		"cid": params.companyid,

		"websiteId": params.websiteid,

		// 事件类型 $pageview：Web浏览页面事件 $WebPageLeave：Web浏览时间事件 $WebClick：Web元素点击事件
		"event": params.event,

		// 事件持续时长 仅事件类型为$pageview、$WebPageLeave时需传入该字段 单位秒，三位小数
		"eventDuration": params.eventDuration || '',

		// 页面ID Cases、News时必填
		"pageId": params.pageId,
		// 独立站页面类型 Products、Video、Qualifications、Cases、News
		"pageType": params.pageType,

		// 网站信息
		"title": window.document.title,
		"url": currentURL,
		"urlPath": window.location.pathname,
		"urlHost": window.location.host,

		// 来源页面
		"referrer": buryingPointData.referrer,
		"referrerHost": buryingPointData.referrer_host,

		// 操作系统版本
		"osVersion": buryingPointData.os_version,

		// 浏览器屏幕信息
		"screenHeight": window.screen.height,
		"screenWidth": window.screen.width,

		// 浏览器信息（后端获取）
		"browser": browserObj.browserName,
		"browserCharset": "",
		"browserLanguage": "",
		"browserVersion": "",

		"lib": "js",
		// 时区偏移量
		"timezoneOffset": new Date().getTimezoneOffset(),

		// 最近加载页面
		"latestLandingPage": localStorage.getItem('latest_landing_page'),
		// 最近流量来源类型 已知:自然搜索流量、引荐流量、直接流量、付费广告流量、社交网站流量、url的domain解析失败、取值异常
		"latestTrafficSourceType": analyzeTraffic(referrer, currentURL),
		// 最近查询关键字
		"latestSearchKeyword": getKeywordFromReferrer(),

		// 最近来源页面
		"latestReferrer": localStorage.getItem('recentSources'),
		// 最近来源host
		"latestReferrerHost": buryingPointData.latestReferrerHost,

		// 制造商
		"manufacturer": "",

		// element Id 仅事件类型为$WebClick时需传入该字段事件为时必须传入 如果是询盘提交，值必须为：inquiry submit
		"elementId": params.elementId,
		"elementType": "",
		"elementContent": "",
		"elementTargetUrl": "",
		"elementPath": "",
		"elementClassName": "",
		"elementSelector": "",

		// 视图端口高度
		"viewportHeight": "",
		// 视图窗口位置
		"viewportWidth": "",
		// 页面x轴地址
		"pageX": "100",
		// 页面y轴地址
		"pageY": "200",
		// 视图窗口位置
		"viewportPosition": "",
		// element位置??
		"elementPosition": ""
	}

	return data
}

// 调取api
function saveEvent(data, url) {
	axios({
		method: "post",
		crossDomain: true,
		url,
		data,
	}).then((res) => {
		if (res.status === 200) {
			console.log('埋点数据')
		} else {
			alert("server not responding");
		}
	});
}

export const getData = (params) => {
	if (params.isPreview !== 'false') return
	const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
	let time = new Date()
	// 如果没有id 或者当前时间大于等于存储的时间戳则不调取接口
	if (!sessionInfo || time.getTime() > sessionInfo.time) return localStorage.removeItem('sessionInfo')

	const data = getParams(params, sessionInfo.id)

	const url = `${params.eventDomain}/cms/events/saveEvent`

	saveEvent(data, url)
}
